import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import L from 'leaflet';

import { makeStyles } from '@material-ui/core/styles'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'


import { MapContainer, TileLayer, Marker, Popup, Icon, LayerGroup, Circle } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '90vw', 
    height: '85vh', 
    maxWidth: '100%'
  },
  mapContainer: {
    width: '100%', 
    height: '100%'
  }
}))

const Map = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [ready, setReady] = useState(false)

  const classes = useStyles()

  const centerCoordinates = [62.38583179, 16.321998712]
  const initZoomLevel = 5

  useEffect(() => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate() //21
    if(typeof window !== undefined) {
      fetch(`https://opendata-download-lightning.smhi.se/api/version/latest/year/${ year }/month/${ month }/day/${ day }/data.json`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result)
          setItems(result.values);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
    }
  }, [])

  useEffect(() => {
    if(typeof window !== undefined) {
      setReady(true)
    }
  }, [])

  const Markers = () => {
    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Laddar...</div>
    } else if(typeof window !== undefined) {
      const icon = L.divIcon({ className: 'blixt-icon' })
      return (
        <MarkerClusterGroup>
          {items.map((marker, index) => {
            return (
            <Marker 
              icon={ icon } 
              position={ [marker.lat, marker.lon] } 
              key={ `${marker.nanoseconds}-${index}` }>
              
            </Marker>
          )})}
        </MarkerClusterGroup>
      )
    }
  }


  return (
      (ready && items.length !== 0) ?
        <div className={ classes.root }>
          <MapContainer center={centerCoordinates} zoom={initZoomLevel} scrollWheelZoom={false} className={classes.mapContainer}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
              <Markers />
          </MapContainer> 
        </div>
      :
        <div>Det finns ingen åska idag</div>
    )
}

export default Map
